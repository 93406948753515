import React from "react"
import Layout from "../modules/layout"
import SEO from "../modules/seo"
import Intro from "../components/intro"
import FourFeatures from "../components/fourFeatures"
import DownloadInStores from "../components/downloadInStores"
import CoreFeature from "../components/coreFeature"
import FullScreenImage from "../components/fullScreenImage"
import feature1 from "../images/appshots/feature-1.png"
import feature2 from "../images/appshots/feature-2.png"
import feature3 from "../images/appshots/feature-3.png"
import feature4 from "../images/appshots/feature-4.png"
import feature5 from "../images/appshots/feature-5.png"
import AppVideo from "../components/appVideo"
import StartNow from "../components/startNow"
import SuccessStories from "../components/successStories"

const IndexPage = () => {
  return (
    <Layout theme="smartwerker" lang="de">
      <SEO title="Smartwerker" />
      <div id="about"/>
      <Intro
        titleColored="Smartwerker."
        title="Die Handwerker App"
        description="Stundenzettel digital erfassen, Austausch von Informationen via Chat, Dokumente ablegen und teilen, Aufgaben erstellen und vieles mehr!"
        btnLabel="Jetzt kostenlos testen"
      />
      <div id="features"/>
      <FourFeatures 
        title1="Aufgaben"
        description1="Erstellen Sie Aufgaben und weisen diese Ihren Kollegen zu. Erledigte Aufgaben werden abgehakt und nichts wird vergessen."
        title2="Material"
        description2="Mit Ladelisten fehlt kein Material mehr beim Kunden vor Ort. Das verbaute Material ist zu jederzeit einsehbar."
        title3="Zeiterfassung"
        description3="Kein Warten mehr auf Stundenzettel der Kollegen. Geleistete Stunden sind in Echtzeit pro Projekt gelistet."
        title4="Bilder und Dokumente"
        description4="Pläne, Baufortschritt und Lieferscheine werden digital zum Projekt gespeichert. So ist alles relevante zu jeder Zeit an einem Ort zu finden."
      />
      <DownloadInStores 
        title="DIE #1 APP FÜR HANDWERKER AUCH IM BROWSER"
        description="Smartwerker verbindet die Kollegen im Büro und beim Kunden vor Ort. Alle notwendigen Informationen (Dokumente, Material, Stundenzettel, Berichte, Bilder) und Chat-Nachrichten können innerhalb der mobielen App und im Browser geteilt und abgerufen – und das in Echtzeit."
      />
      <CoreFeature 
        additionalClass="ml-minus"
        category="FUNKTION"
        title="Projekte"
        description="Legen Sie in Smartwerker ein Projekt zur kompletten Abwicklung Ihres Auftrages an. Somit können Sie alle Funktionen von Smartwerker nutzen und behalten stets einen guten Überblick über Ihre Projekte/Aufträge."
        btnLabel="Mehr erfahren"
        btnTarget="https://smartwerker.app/"
        img={feature1}
      />
      <CoreFeature 
        additionalClass=""
        category="FUNKTION"
        title="Berichte erstellen und signieren"
        description="Erfassen Sie in einem Bericht die geleisteten Stunden und das verbrauchte Material. Nach der Erstellung des Berichts lässt sich dieser per E-Mail an den Kunden verschicken und sogar vor Ort digital unterschreiben."
        btnLabel="Mehr erfahren"
        btnTarget="https://smartwerker.app/"
        img={feature2}
      />
      <CoreFeature 
        additionalClass="ml-minus"
        category="FUNKTION"
        title="Chat und Sprachnachrichten"
        description="Der Austausch via Chat mit den Kollegen vom Smartphone oder dem PC ist kein Problem und das ganze natürlich DSGVO-konform."
        btnLabel="Mehr erfahren"
        btnTarget="https://smartwerker.app/"
        img={feature3}
      />
      <CoreFeature 
        additionalClass=""
        category="FUNKTION"
        title="Zeiterfassung"
        description="Erfassen Sie alle geleisteten Stunden zu einem Projekt. Der Zugriff auf die erfassten Zeiten in Echtzeit, bietet Ihnen die Möglichkeit, dass im Büro bereits während des Arbeitstages Rechnungen erstellt/vorbereitet werden können."
        btnLabel="Mehr erfahren"
        btnTarget="https://smartwerker.app/"
        img={feature4}
      />
      <FullScreenImage
        additionalClass=""
        img={feature5}
      />
      <AppVideo 
        title="Smartwerker in Action"
        description="Sieh Dir an wie Smartwerker Dir und Deinem Unternehmen helfen kann."
        videoId="hTD4uRX31Xc"
      />
      <div id="start"/>
      <StartNow 
        title="Jetzt kostenlos testen"
        description="Starten Sie jetzt ganz einfach in nur wenigen Schritten. und digitalisieren Sie Ihren Handwerksbetrieb! Nutzen Sie Smartwerker im Browser, auf dem Smartphone oder auf dem Tablet. Laden Sie sich jetzt unsere App aus dem Apple App Store oder dem Google Play Store herunter."
        btnLabel="JETZT LOSLEGEN"
      />
      <div id="testimonials"/>
      <SuccessStories 
        title="Erfolgsgeschichten"
      />
    </Layout>
  )
}

export default IndexPage

// export const query = graphql`
// query indexHomeQuery {
//   allContentfulSmartwerkerHome {
//     nodes {
//       id
//       title
//       headerImage {
//         fluid(maxWidth: 1600 quality: 50) {
//           src
//           srcSet
//           srcWebp
//           srcSetWebp
//           sizes
//           aspectRatio
//         }
//         description
//       }
//       components {
//         ... on ContentfulContentTeaser {
//           id
//           contenTeaserTitle
//           contenTeaserContent {
//             json
//           }
//           contenTeaserCarousel {
//             fluid {
//               src
//               srcSet
//               srcWebp
//               srcSetWebp
//               sizes
//               aspectRatio
//             }
//           }
//         }
//         ... on ContentfulPromotionTeaser2 {
//           id
//           promotionTeaserTitle
//           promotionTeaserImageRightTop {
//             fluid {
//               src
//               srcSet
//               srcWebp
//               srcSetWebp
//               sizes
//               aspectRatio
//             }
//           }
//           promotionTeaserImageRightBottom {
//             fluid {
//               src
//               srcSet
//               srcWebp
//               srcSetWebp
//               sizes
//               aspectRatio
//             }
//           }
//           promotionTeaserTitleImage {
//             fluid {
//               src
//               srcSet
//               srcWebp
//               srcSetWebp
//               sizes
//               aspectRatio
//             }
//           }
//           promotionTeaserImageLeft {
//             fluid {
//               src
//               srcSet
//               srcWebp
//               srcSetWebp
//               sizes
//               aspectRatio
//             }
//           }
//           promotionTeaserImageBottomLeft {
//             fluid {
//               src
//               srcSet
//               srcWebp
//               srcSetWebp
//               sizes
//               aspectRatio
//             }
//           }
//           promotionTeaserImageBottomRight {
//             fluid {
//               src
//               srcSet
//               srcWebp
//               srcSetWebp
//               sizes
//               aspectRatio
//             }
//           }
//           promotionTeaserContent {
//             json
//           }
//         }
//       }
//     }
//   }

//   allInstaNode {
//     edges {
//       node {
//         id
//         likes
//         comments
//         mediaType
//         preview
//         original
//         timestamp
//         caption
//         localFile {
//           childImageSharp {
//             fluid {
//               src
//               srcSet
//               srcWebp
//               srcSetWebp
//               sizes
//               aspectRatio
//             }
//           }
//         }
//         # Only available with the public api scraper
//         thumbnails {
//           src
//           config_width
//           config_height
//         }
//         dimensions {
//           height
//           width
//         }
//       }
//     }
//   }
// }
// `